import React from 'react'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'
import ImageOne from '../../components/images/cg-one'
import ImageTwo from '../../components/images/cg-two'

const IndividualStoryThree = () => (
  <Layout>
    <SEO title="Charles Garrison" />
    <h2>Charles Garrison</h2>
    <section>
      <p>Lt Charles Garrison served in two wars. A volunteer in one, less so in the other. He signed up to serve in World War II after the Pearl Harbor attack. He was stationed in Corpus Christi, Texas when he got his wings and married his high school sweetheart, Enid Nichols in October of 1943.</p>
      <ImageOne />
      <p>After further training in Miami, Florida he was assigned to the Atlantic Fleet and saw duty on various carriers as a Navy pilot. Following the cessation of hostilities in Europe, he was on the Carrier USS Boxer, on the way to the Pacific Fleet when the Japanese surrendered. Continuing on they were with the occupation forces for about a year. He returned to the states and remained in the Navy, stationed in Key West, Florida until 1947 when he returned to Adrian, Missouri to help with his family's farm. He had hoped someday to become a commercial pilot, as did countless other young veterans with flying in their blood. He loved flying and joined the Naval Reserves in nearby Olathe, Kansas. That way, at least every month he could get in the cockpit and fly his Corsair. Neighbors and friends remember him as happy go lucky: more than one remembers him buzzing their farm on his reserve weekends. When the Korean War broke out his reserve unit was activated, and the family moved to Coronado, California for his re-qualification. About a year later he left for Korea with VF 884 on the carrier USS Boxer again. Just prior to shipping out, he moved his family back to Adrian and bought a house near the grade school.</p>
      <p>Not unlike many others who had resumed their civilian lives after World War II and were then called back to active duty, he was resentful towards this second call to war, but did not complain about it. It was a common feeling in his unit. While the resentment didn't compromise his unit's performance, it did show itself in the unit's name- "The Bitter Birds." However it was said that he once quoted Navy Commissioner Stephen Decatur "Our Country, …may she always be in the right; but right or wrong, our country!"</p>
      <p>On May 18, 1951, his was third in a line of four corsairs, flying off the USS Boxer, when attacking a North Korean truck convoy and shot down. He was in sight of his wingman, LTJG Dragastin, when hit and turned south in anticipation of bailing out. The other two members of his flight caught up soon after. His plane on fire, he tried to bail out but was seen to be in trouble. After jettisoning the canopy he was momentarily caught up in the cockpit, but after freeing himself he hit the tail assembly and probably blacked out for a period, because his chute didn't open until he was very close to the ground. He landed near a shack where he was seen by his wingman, LTJG Dragastin plus a Rescue Combat Air Patrol, pointing to his belly. Someone from the shack kept trying to get to him. Not knowing if they were friend or enemy, Dragastin and the RESCAP shot at him whenever the unknown Korean came toward Garrison. While flying low to protect Charles, LtJG Dragastin was shot by small arms fire, seen by the RESCAP to slump over and flew into a mountain side. For his courageous acts, in complete disregard of his personal safety, LtJG Dragastin was posthumously awarded the Navy Cross. Running out of fuel, others of Garrison's flight had to return to a nearby base, leaving Air Force replacement Mustangs to keep an eye on the situation. The base, in turn was being overrun, and Garrison's position was insecure. Forty-five minutes later when the helicopter arrived, Garrison and his chute were gone.</p>
      <ImageTwo />
      <p>Charles Garrison and his wife, Enid, were born and raised in Adrian, Missouri and were high school sweethearts. Younger daughter Janis doesn't remember him; her older sister, Karen, is one of those who remember him waggling his wings when flying over their home. It seemed that whenever Janis or her sister would ask questions about their Dad everyone looked so sad, that eventually they stopped asking. With the perspective of being adults now, they realize talking about it was just too hard for their mother. Their mother's last letter to him had said "We'll be here waiting for you." And she lived up to it. She lived out her life in the house he bought for them just before shipping out to Korea, and never remarried. Her Mother once mentioned that "she was a one-man woman", and lived accordingly. A memorial service was given for Charles after he was legally declared dead in 1954. Charles' gravestone states "He gave that last full measure of devotion." His parents both passed away believing he would come home someday.</p>
      <p>The family got its first TV to watch the details of the POWs being released from Korea. It was only decades later they realized the purchase was to make sure they had the most current info in case Charles was released. Karen was aware of their father's MIA status, but it wasn't until reading newspaper clippings and correspondence after their Grandmother's death that Janis found out about the reference to her father's last seen alive status. She started searching on the internet for information and found the Korean War Project and began to fill in some of the blanks, but also left open questions. Reading his letters after her Grandmother's death was when the sense of loss kicked in, along with the grieving and a desire to learn of his fate and keep his memory alive. Janis learned the US re-occupied the area he was lost in a few days after his shoot down, but there was no sign of her father. As Janis inquired into her father's loss, the Navy Casualty office introduced her to the released American POW who believed he had seen him in a camp in Wonsan. The POW witness said her father's leg was badly injured, the suspicion being he'd broken his leg trying to parachute from his burning plane. Later, Janis found a reference to another POW who remembered Charles, near death, being put on a truck to Pyongyang, purportedly for health care. That was the last time he was seen alive. Both Karen and Janis are still trying to find out if he made it to Pyongyang or if he died on the way.</p>
      <p>In 2000 Janis' search was publicized in the Korea Times, and read by a Korean American, a poet, who, while a youth in Korea had buried an American on a roadside just South of the DMZ in the area where Charles landed. Soon thereafter the US Military flew him to Korea to assist in looking for Lt Garrison's remains. Janis remembers expecting that every phone call might bring good news, and the growing disappointment as time passed and, ultimately, no remains were found.</p>
      <p>Just this past Memorial Day, nearly 60 years after his loss, a new Veterans' Memorial in his hometown of Adrian was dedicated and named after him and a marble bench was placed there in his memory.</p>
      <p>Now an adult, Janis wonders if she could have survived as her mother did after Charles was lost. She still hopes to fill in the unknowns about her father's case, and perhaps see him returned someday. Until then, she remains committed to keeping Charles' memory alive, and works with Korean Mia organizations that share her concern.</p>
    </section>
  </Layout>
)

export default IndividualStoryThree
